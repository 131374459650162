"use client";

import { PropsWithChildren } from "react";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { createQueryClient } from "@/common/query-client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { KVStorage } from "@/utils/redis-storage-adapter";
import { isServer } from "@/utils/environment";
import { TWENTY_FOUR_HOURS_IN_MS } from "@/constants/time-formats";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

let browserQueryClient: QueryClient | undefined = undefined;

const getQueryClient = () => {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return createQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important so we don't re-make a new client if React
    // supsends during the initial render
    if (!browserQueryClient) browserQueryClient = createQueryClient();
    return browserQueryClient;
  }
};

// const persister = createSyncStoragePersister({
//   key: "QUERY_CACHE",
//   storage: isServer() ? undefined : localStorage,
// });

// const asyncStoragePersister = createAsyncStoragePersister({
//   key: "QUERY_CACHE",
//   storage: isServer() ? KVStorage.getInstance() : localStorage,
// });

export function QueryProvider({ children }: PropsWithChildren) {
  // NOTE: Avoid useState when initializing the query client if you don't
  //       have a suspense boundary between this and the code that may
  //       suspend because React will throw away the client on the initial
  //       render if it suspends and there is no boundary
  const client = getQueryClient();

  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
  // return (
  //   <PersistQueryClientProvider
  //     client={client}
  //     persistOptions={{
  //       persister: asyncStoragePersister,
  //       maxAge: TWENTY_FOUR_HOURS_IN_MS,
  //     }}>
  //     {children}
  //   </PersistQueryClientProvider>
  // );
}
//  // <PersistQueryClientProvider client={client} persistOptions={{ persister, maxAge: TWENTY_FOUR_HOURS_IN_MS }}>
//     {/* {children} */}
//     {/* <ReactQueryStreamedHydration>{children}</ReactQueryStreamedHydration> */}
//     {/* <ReactQueryDevtools initialIsOpen={false} /> */}
//   // </PersistQueryClientProvider>
