import { QueryClient } from "@tanstack/react-query";
import { QueryOptions } from "@tanstack/query-core";
import { AxiosError } from "axios";
import { ONE_HOUR_IN_MS } from "@/constants/time-formats";

// export const queryClient = registerService("queryClient", () => createQueryClient());

export function createQueryClient() {
  const retry: QueryOptions["retry"] = (failureCount, e) => {
    const error = e as AxiosError;

    console.log("****** Query Client Error:", {
      message: error.message,
      status: error.response?.status,
    });

    // TODO: check this specifc to locate2u
    if (error.message.includes("401") || error.response?.status === 401) {
      return failureCount < 3;
    }

    return false;
  };

  const cacheTime = ONE_HOUR_IN_MS;

  return new QueryClient({
    defaultOptions: {
      // query options
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: cacheTime,
        gcTime: cacheTime,
        retry,
      },
      // mutation options
      mutations: {
        gcTime: cacheTime,
        retry,
      },
    },
  });
}
