import { Airport } from "@/utils/types";
import { PropsWithChildren, createContext, useContext, useRef } from "react";
import { createStore } from "zustand";
import { useStoreWithEqualityFn } from "zustand/traditional";

interface AppProps {
  from?: Airport;
  to?: Airport;
  date?: Date;
  serviceDate?: Date;
  searchSourceReference?: string;
}

interface AppState extends AppProps {
  setFrom: (from: Airport) => void;
  setTo: (to: Airport) => void;
  setDate: (date: Date) => void;
  setServiceDate: (date: Date) => void;
  setSearchSourceReference: (value: string) => void;
}

// store
const createAppStore = (initProps?: Partial<AppProps>) => {
  const DEFAULT_PROPS: AppProps = {};

  return createStore<AppState>()((set) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    setFrom: (from) => set({ from }),
    setTo: (to) => set({ to }),
    setDate: (date) => set({ date }),
    setServiceDate: (date) => set({ serviceDate: date }),
    setSearchSourceReference: (value) => set({ searchSourceReference: value }),
  }));
};

type AppStore = ReturnType<typeof createAppStore>;
type AppProviderProps = PropsWithChildren<AppProps>;

// context
const AppContext = createContext<AppStore | null>(null);

// provider
export function AppStoreProvider({ children, ...props }: AppProviderProps) {
  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    storeRef.current = createAppStore(props);
  }

  return <AppContext.Provider value={storeRef.current}>{children}</AppContext.Provider>;
}

// hook
export function useAppStore<T = AppState>(
  selector: (state: AppState) => T = (state) => state as T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(AppContext);

  if (!store) throw new Error("Missing AppStoreProvider in the tree");

  return useStoreWithEqualityFn(store, selector, equalityFn);
}
